exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-address-js": () => import("./../../../src/pages/address.js" /* webpackChunkName: "component---src-pages-address-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-free-js": () => import("./../../../src/pages/free.js" /* webpackChunkName: "component---src-pages-free-js" */),
  "component---src-pages-happyclients-js": () => import("./../../../src/pages/happyclients.js" /* webpackChunkName: "component---src-pages-happyclients-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-reversing-diabetes-js": () => import("./../../../src/pages/reversing-diabetes.js" /* webpackChunkName: "component---src-pages-reversing-diabetes-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

